<template>
        <div>
            <v-card-text>
                <div v-show="!codeInputActive" class="pt-4">
                    <slot name="initialText">
                        <p>Wir senden ihnen eine SMS mit einem Bestätigungscode zu, falls Sie auf abschicken drücken.</p>
                        <p v-if="!user.loggedIn">Sie loggen sich hiermit bei uns ein.</p>
                    </slot>
                    <vue-tel-input v-model="newNumber"
                                   v-on:validate="setNewNumberValid"
                                   valid-characters-only
                                   ref="phoneNumberInput"
                                   placeholder="Geben Sie ihre Telefonnummer ein"
                                   mode='international'></vue-tel-input>
                    <v-layout align-center class="pt-2 pb-2" v-show="!captchaLoading">
                        <div id="recaptcha-container" v-show="!captchaLoading"></div>
                    </v-layout>
                    <v-layout align-center justify-center class="pt-5 pb-8" v-if="captchaLoading">
                        <v-progress-circular
                                indeterminate
                                size="40"
                                color="primary"
                        ></v-progress-circular>
                    </v-layout>
                </div>
                <div v-show="codeInputActive" class="pt-4">
                    <p v-if="codeWrong" class="font-weight-black">Der eingegebene Code ist falsch! Bitte geben Sie den richtigen Code ein.</p>
                    <p v-else>Bitte geben Sie hier den erhaltenen Bestätigungscode ein.</p>
                    <v-text-field
                            label="Bestätigungscode"
                            single-line
                            outlined
                            maxlength="6"
                            v-model="confirmationCode"
                    ></v-text-field>
                </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <div v-if="!waiting">
                    <v-btn @click="submitNewNumber" :disabled="!this.captchaDone || !this.newNumberValid" text v-if="!codeInputActive"> Abschicken </v-btn>
                    <v-btn @click="submitConfirmationCode" :disabled="confirmationCode.length!==6" text v-else> Abschicken </v-btn>
                </div>
                <div v-else class="pr-10">
                    <v-progress-circular
                            indeterminate
                            size="36"
                            color="primary"
                    ></v-progress-circular>
                </div>
            </v-card-actions>
        </div>
</template>


<script>

    import firebase from 'firebase/app';
    import {mapGetters} from "vuex";

export default {
        name: "PhoneWizard",
        components: {VueTelInput: () => import('vue-tel-input').then(module=>{
                const {VueTelInput} = module
                return VueTelInput
            })},
        computed:{
            ...mapGetters({
                user: 'userStore/user',
                phoneNumber: 'userStore/phoneNumber',
            }),
        },
        props: {
            visible: Boolean,
            initialPhoneNumber:String,
        },
        data() {
            return {
                newNumber: "",
                newNumberValid:false,
                confirmationCode:"",
                codeInputActive:false,
                verificationId:"",
                captchaLoading:true,
                captchaDone:false,
                codeWrong:false,
                waiting:false,
            };
        },
        methods:{
            submitNewNumber() {
                if (this.captchaDone && this.newNumberValid) {
                    let appVerifier = window.recaptchaVerifier;
                    this.waiting = true
                    var provider = new firebase.auth.PhoneAuthProvider();
                    provider.verifyPhoneNumber(this.newNumber, appVerifier)
                        .then((verificationId) => {
                            // SMS sent. Prompt user to type the code from the message, then sign the
                            // user in with confirmationResult.confirm(code).
                            this.waiting = false
                            this.codeInputActive = true;
                            this.verificationId = verificationId;
                        }).catch(error => {
                          if(error.code == "auth/invalid-phone-number") {
                            let alertString = 'Die eingegebene Telefonnummer ist nicht gültig.'
                            if(error.message == 'TOO_LONG') {
                              alertString += ' Sie ist zu lang.'
                            }
                            alert(alertString)
                          }
                        console.log(error)
                        this.waiting = false
                        // Error; SMS not sent
                        // ...
                    });
                }
            },
            submitConfirmationCode(){
                let phoneCredential = firebase.auth.PhoneAuthProvider.credential(this.verificationId, this.confirmationCode);
                this.waiting = true
                //If the user is already logged in, merge the new Phone Auth Acc with their already existing Account
                //If they are not logged in, create a PhoneAuth Account and log them in.
                if(this.user.loggedIn) {
                    firebase.auth().currentUser.updatePhoneNumber(phoneCredential)
                        .then(()=>{
                            this.$store.commit('userStore/SET_USER_ATTRIBUTE', {key:"phoneNumber", value:this.newNumber})
                            this.$emit('successful', this.newNumber)
                        }).catch((error) => {
                        console.log(error)
                        if(error.code === 'Auth/credential-already-in-use') {
                            if (confirm("Es wurde bereits ein bestehendes Benutzerkonto mit dieser Telefonnummer gefunden. \n Sie müssen zuerst bei dem bestehenden Konto die Nummer enfternen um sie bei dem momentanen Konto hinzuzufügen. \n Wollen Sie sich bei dem bestehenden Konto mit dieser Telefonnummer jetzt anmelden?")) {
                                this.user.hasCheckedLogin = false
                                firebase.auth().signInWithCredential(error.credential).then(()=>{
                                    this.$emit('successful', this.newNumber)
                                })
                            } else {
                                this.$emit('successful', this.newNumber)
                            }
                        } else {
                            this.codeWrong = true
                            this.waiting = false
                        }

                    });
                } else {
                    firebase.auth().signInWithCredential(phoneCredential).then(()=>{
                        this.$emit('successful', this.newNumber)
                    }).catch((error)=>{
                        console.log(error)
                        this.codeWrong = true
                        this.waiting = false
                    })
                }

            },
            setNewNumberValid(obj){
                this.newNumberValid = obj.valid
                //This is done, so the initial phone number is instantly validated.
                //Without this code newNumber would stay equal to initialPhoneNumber,
                //With this code, it gets validated and changed into a correct string
                this.newNumber = this.$refs.phoneNumberInput.phone
            },
        },
        mounted() {
            if(this.initialPhoneNumber){
                this.newNumber = this.initialPhoneNumber
            }
            window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container');
            window.recaptchaVerifier.render().then(()=>{
                this.captchaLoading = false
            })
            window.recaptchaVerifier.verify().then(()=>{
                this.captchaDone = true
            })
        },
    watch:{
        visible(val){
            if (val) {
                this.captchaLoading = true
                this.captchaDone = false
                this.newNumber = ""
                this.confirmationCode = ""
                this.codeInputActive = false
                this.verificationId = ""
                this.codeWrong = false
                this.waiting = false
                window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container');
                window.recaptchaVerifier.render().then(()=>{
                    this.captchaLoading = false
                })
                window.recaptchaVerifier.verify().then(()=>{
                    this.captchaDone = true
                })
            }
            if(!val){
                window.recaptchaVerifier.clear()
            }

        }

    }

}
</script>

<style scoped>

</style>